<template>
  <div class="table-container">
    <a-anchor>
      <div v-if="loading" style="text-align: center">
        <a-progress
          type="circle"
          :percent="loadingPercent"
          style="margin: 10px"
        />
      </div>
      <div v-else>
        <div v-if="data.length > 0" id="table-count">
          總共筆數：{{ dataLength }}
        </div>
        <a-table
          :pagination="tableSetting"
          :bordered="true"
          :columns="columns"
          :data-source="data"
          :loading="loading"
        >
          <template #expandedRowRender="{ record }">
            <a-table
              :columns="innerColumns"
              :data-source="filterStore(record.store_id)"
              :pagination="false"
            ></a-table>
          </template>
        </a-table>
      </div>
    </a-anchor>
  </div>
</template>

<script>
  import { reactive, computed, toRef, ref, watch } from 'vue'
  import { useStore } from 'vuex'
  import { numberComma } from '@/utils/index'
  const columns = [
    {
      title: '大分區',
      dataIndex: 'area_name',
      align: 'center',
    },
    {
      title: '小分區',
      dataIndex: 'area_sub_name',
      align: 'center',
    },
    {
      title: '門市',
      dataIndex: 'store_name',
      align: 'center',
    },
    {
      title: '個案量測人數',
      dataIndex: 'case',
      align: 'center',
    },
    {
      title: '個案量測人次',
      dataIndex: 'caseTime',
      align: 'center',
    },
    {
      title: '量測紀錄總人次',
      dataIndex: 'peopleTime',
      align: 'center',
    },
    {
      title: '個案量測紀錄項目數',
      dataIndex: 'caseMeasurements',
      align: 'center',
    },
    {
      title: '量測紀錄總項目總數',
      dataIndex: 'peopleMeasurements',
      align: 'center',
    },
  ]
  const innerColumns = [
    {
      title: '會員卡號',
      dataIndex: 'cardNumber',
      align: 'center',
    },
    {
      title: '姓名',
      dataIndex: 'name',
      align: 'center',
    },
    {
      title: '血壓量測次數',
      dataIndex: 'pbTimes',
      align: 'center',
    },
    {
      title: '血糖量測次數',
      dataIndex: 'glucoseTimes',
      align: 'center',
    },
    {
      title: '血氧量測次數',
      dataIndex: 'osTimes',
      align: 'center',
    },
    {
      title: '體溫量測次數',
      dataIndex: 'bodyTempTimes',
      align: 'center',
    },
    {
      title: '身體組成量測次數',
      dataIndex: 'bodyTimes',
      align: 'center',
    },
  ]
  export default {
    props: ['searchResult'],
    setup(props) {
      const store = useStore()
      const dataRaw = toRef(props, 'searchResult')
      const data = ref([])
      const loading = ref(false)
      const loadingPercent = ref(0)
      const userCalc = ref([])
      const dataLength = computed(() => numberComma(data.value.length))
      const windowHeight = window.innerHeight
      const storeList = store.getters['store/storeList']
      const tableSetting = reactive({
        currentPage: 1,
        pageSize: 20,
        position: 'top',
        simple: true,
      })

      const calcData = async () => {
        loading.value = true
        data.value = []
        userCalc.value = []
        let i = 0
        for (const item of dataRaw.value) {
          const store_id = item.location.code
          // 查詢門市資料
          const stroeInfo = storeList.find((item) => item.store_id == store_id)

          // 如果該門市尚未初始化資料則新增一筆
          if (data.value.findIndex((i) => i.store_id == store_id) == -1) {
            data.value.push({
              key: store_id,
              store_id: store_id,
              area_name: stroeInfo.area_name,
              area_sub_name: stroeInfo.area_sub_name,
              store_name: stroeInfo.store_name,
              case: 0,
              caseTime: 0,
              peopleTime: 0,
              caseMeasurements: 0,
              peopleMeasurements: 0,
            })
          }

          // 取出統整的門市資料
          const storeItem = data.value.find((i) => i.store_id == store_id)

          // 如果該會員尚未初始化資料則新增一筆
          if (
            item.subject != null &&
            userCalc.value.findIndex(
              (i) =>
                i.store_id == store_id && i.user_id == item.subject.memberID
            ) == -1
          ) {
            storeItem.case++ // 個案量測人數
            if (item.subject.cache != null) {
              userCalc.value.push({
                key: `${store_id}_${item.subject.memberID}`,
                store_id: store_id,
                user_id: item.subject.memberID,
                cardNumber: item.subject.cardNumber,
                name: item.subject.cache.name,
                pbTimes: 0,
                osTimes: 0,
                glucoseTimes: 0,
                bodyTempTimes: 0,
                bodyTimes: 0,
              })
            } else {
              const custInfo = await store.dispatch('cust/getCustInfo', {
                memberID: item.subject.memberID,
              })
              userCalc.value.push({
                key: `${store_id}_${item.subject.memberID}`,
                store_id: store_id,
                user_id: item.subject.memberID,
                cardNumber: item.subject.cardNumber,
                name: custInfo.name,
                pbTimes: 0,
                osTimes: 0,
                glucoseTimes: 0,
                bodyTempTimes: 0,
                bodyTimes: 0,
              })
            }
          }

          // 加總門市數值
          if (item.subject !== null) {
            storeItem.caseTime++ // 個案量測人次
            if (item.bpMeasurements.length > 0) storeItem.caseMeasurements++
            if (item.glucoseMeasurements.length > 0)
              storeItem.caseMeasurements++
            if (item.oxygenSatMeasurements.length > 0)
              storeItem.caseMeasurements++
            if (item.bodyTempMeasurements.length > 0)
              storeItem.caseMeasurements++
            if (item.bodyWeightFatMeasurements.length > 0)
              storeItem.caseMeasurements++
          }
          storeItem.peopleTime++ // 量測紀錄總人次
          if (item.bpMeasurements.length > 0) storeItem.peopleMeasurements++
          if (item.glucoseMeasurements.length > 0)
            storeItem.peopleMeasurements++
          if (item.oxygenSatMeasurements.length > 0)
            storeItem.peopleMeasurements++
          if (item.bodyTempMeasurements.length > 0)
            storeItem.peopleMeasurements++
          if (item.bodyWeightFatMeasurements.length > 0)
            storeItem.peopleMeasurements++

          // 加總個人數值
          loadingPercent.value = parseInt((i / dataRaw.value.length) * 100)
          if (item.subject == null) continue
          const userItem = userCalc.value.find(
            (i) => i.key == `${store_id}_${item.subject.memberID}`
          )
          if (item.bpMeasurements.length > 0) userItem.pbTimes++
          if (item.glucoseMeasurements.length > 0) userItem.glucoseTimes++
          if (item.oxygenSatMeasurements.length > 0) userItem.osTimes++
          if (item.bodyTempMeasurements.length > 0) userItem.bodyTempTimes++
          if (item.bodyWeightFatMeasurements.length > 0) userItem.bodyTimes++
          i++
        }

        data.value.sort(function (a, b) {
          return a.area_name > b.area_name ? 1 : -1
        })
        loading.value = false
      }

      const filterStore = (store_id) =>
        userCalc.value
          .filter((item) => store_id == item.store_id)
          .sort((a, b) => a.cardNumber - b.cardNumber)

      watch(
        () => props.searchResult,
        () => {
          calcData()
        }
      )

      return {
        columns,
        innerColumns,
        tableSetting,
        data,
        userCalc,
        dataLength,
        windowHeight,
        filterStore,
        loading,
        loadingPercent,
      }
    },
  }
</script>

<style lang="less">
  .table-container {
    position: relative;
    #table-count {
      position: absolute;
      right: 200px;
      top: 10px;
    }
  }
  .ant-pagination.ant-table-pagination {
    float: right;
    text-align: right;
    margin: -25px 0 0 0;
  }
</style>
