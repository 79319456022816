<template>
  <v-chart autoresize :option="option" />
</template>

<script>
  import { ref, onMounted, watch, toRef } from 'vue'
  import moment from 'moment'
  import { getChartYAxisRange } from '@/utils/index'
  export default {
    props: {
      data: {
        type: Object,
        default: () => {},
      },
    },
    setup(props) {
      const dataCopy = toRef(props, 'data')
      let persionCase = [], // 個案人數
        persionTime = [], // 人次
        persionCaseTime = [] //個案人次
      const option = ref(null)
      const echartInit = () => {
        // 人數刻度範圍
        const personValueRange = {
          min: 0,
          max: Math.max.apply(
            Math,
            Object.values(dataCopy.value).map((o) => o.persion.case)
          ),
        }
        const personChartRange = getChartYAxisRange(personValueRange)
        // 人次刻度範圍
        const personTimeValueRange = {
          min: 0,
          max: Math.max.apply(
            Math,
            Object.values(dataCopy.value).map((o) => o.persionTime.total)
          ),
        }
        const personTimeChartRange = getChartYAxisRange(personTimeValueRange)

        for (const key in dataCopy.value) {
          persionCase.push([key, dataCopy.value[key].persion.case]) // 個案人數
          persionTime.push([key, dataCopy.value[key].persionTime.total]) // 人次
          persionCaseTime.push([key, dataCopy.value[key].persionTime.case]) // 個案人次
        }
        const data = [
          {
            name: '個案',
            type: 'bar',
            barWidth: 10,
            data: persionCase,
          },
          {
            name: '總人次',
            type: 'line',
            barWidth: 10,
            data: persionTime,
            yAxisIndex: 1,
          },
          {
            name: '個案人次',
            type: 'line',
            barWidth: 10,
            data: persionCaseTime,
            yAxisIndex: 1,
          },
        ]
        option.value = {
          backgroundColor: 'white',
          title: {
            text: '量測人數/人次',
            left: 'center',
            textStyle: {
              fontSize: '20',
            },
          },
          color: ['#F2994A', '#27B3EF', '#2F80ED'],
          grid: {
            left: '60',
            right: '5%',
            bottom: '70',
          },
          tooltip: {
            trigger: 'axis',
            formatter: (params) => {
              let tar = `<table>
                <tr style="height: 30px;vertical-align: top;">
                  <td>量測日期</td>
                  <td align="right">
                  ${moment(params[0].value[0]).format('YYYY-MM-DD')}</td>
                </tr>
                <tr>
                  <td><span class="chart-tooltip-point" style="background-color:${
                    params[0].color
                  };"></span>個案：</td>
                  <td align="right">${params[0].data[1]}人</td>
                </tr>
                <tr>
                  <td><span class="chart-tooltip-point" style="background-color:${
                    params[1].color
                  };"></span>總人次：</td>
                  <td align="right">${params[1].data[1]}人次</td>
                </tr>
                <tr>
                  <td><span class="chart-tooltip-point" style="background-color:${
                    params[2].color
                  };"></span>個案人次：</td>
                  <td align="right">${params[2].data[1]}人次</td>
                </tr>
                </table>`
              return tar
            },
          },
          toolbox: {
            feature: {
              dataView: { show: true, readOnly: false },
              saveAsImage: { show: true },
            },
            top: 'bottom',
          },
          legend: {
            data: ['個案', '總人次', '個案人次'],
            left: 'right',
          },
          xAxis: [
            {
              type: 'time',
              axisPointer: {
                type: 'shadow',
              },
              minInterval: 3600 * 24 * 1000,
              maxInterval: 3600 * 24 * 1000,
              axisLabel: {
                formatter: (value) =>
                  moment(value).format('dd') +
                  '\n' +
                  moment(value).format('M/D'),
              },
              minorTick: {
                show: true,
                splitNumber: 2,
                length: 3,
              },
              splitLine: {
                lineStyle: {
                  color: '#99E1D6',
                  width: 2,
                },
              },
              minorSplitLine: {
                show: true,
                lineStyle: {
                  color: '#BFECE5',
                  type: 'dashed',
                },
              },
            },
          ],
          yAxis: [
            {
              type: 'value',
              name: '人數',
              min: personChartRange.min,
              max: personChartRange.max,
              interval: personChartRange.interval,
              axisLabel: {
                formatter: '{value}',
              },
              nameLocation: 'start',
              nameTextStyle: { align: 'right' },
              offset: 14,
            },
            {
              type: 'value',
              name: '人次',
              min: personTimeChartRange.min,
              max: personTimeChartRange.max,
              interval: personTimeChartRange.interval,
              axisLabel: {
                formatter: '{value}',
              },
              nameLocation: 'start',
              nameTextStyle: { align: 'left' },
              offset: 14,
            },
          ],
          dataZoom: [
            {
              type: 'inside',
              start: 0,
              end: 100,
              minValueSpan: 3600 * 24 * 1000 * 7,
              maxValueSpan: 3600 * 24 * 1000 * 30,
            },
            {
              start: 0,
              end: 100,
            },
          ],
          series: data,
        }
      }
      onMounted(() => {
        echartInit()
      })

      watch(
        () => props.data,
        () => {
          echartInit()
        }
      )

      return {
        option,
      }
    },
  }
</script>

<style lang="less">
  .a {
    margin-bottom: 12px;
  }
</style>
