<template>
  <div class="operationReportIndex-container">
    <a-page-header title="數據總覽首頁">
      <template #tags>
        <QuestionCircleOutlined />
      </template>
    </a-page-header>
    <a-row>
      <a-col :xs="24" :sm="12" :md="12" :lg="12" :xl="8">
        查詢日期
        <a-range-picker
          :format="dateFormat"
          style="margin-left: 20px"
          v-model:value="searchForm.date"
          @change="
            () => {
              userDateRecord = []
              dateRecord = {}
              searchResult = []
            }
          "
          :ranges="{
            今天: [dateAdd(0), dateAdd(0)],
            昨天: [dateAdd(-1), dateAdd(-1)],
            前7日: [dateAdd(-8), dateAdd(-1)],
            前30日: [dateAdd(-31), dateAdd(-1)],
            前90日: [dateAdd(-91), dateAdd(-1)],
          }"
        ></a-range-picker>
      </a-col>
      <a-col :xs="24" :sm="12" :md="12" :lg="12" :xl="16">
        查詢範圍
        <a-button
          type="primary"
          @click="
            () => {
              storeModalVisible = true
            }
          "
        >
          選取門市
        </a-button>
        <div>
          <StoreTags />
        </div>
      </a-col>
    </a-row>
    <a-row>
      <a-col :xs="24" :sm="12" :md="12" :lg="8" :xl="4">
        <a-button
          type="primary"
          @click="getRecord"
          :disabled="searchForm.store_id == '' || searchForm.date == ''"
          style="width: 90%"
        >
          查詢
        </a-button>
      </a-col>
      <a-col :xs="24" :sm="12" :md="12" :lg="8" :xl="4">
        <a-button
          type="primary"
          @click="exportExcel"
          :disabled="searchResult.length == 0"
          style="width: 90%"
        >
          匯出(健康紀錄清冊)
        </a-button>
      </a-col>
      <a-col :xs="24" :sm="12" :md="12" :lg="8" :xl="4">
        <a-button
          type="primary"
          @click="exportExcel1"
          :disabled="Object.keys(dateRecord).length == 0"
          style="width: 90%"
        >
          匯出(門市量測統計報表)
        </a-button>
      </a-col>
    </a-row>
    <a-spin :spinning="isLoading" tip="Loading...">
      <div
        style="border-bottom: 1px solid #000000; width: 208px; margin: 20px 0px"
      >
        <span style="font-weight: bold; font-size: 20px; line-height: 26px">
          營運數據
        </span>
      </div>

      <a-row :gutter="16">
        <a-col :xs="24" :sm="12" :md="12" :lg="12" :xl="8">
          <a-card :bodyStyle="cardBodyStype">
            <div class="card-title">
              <UserOutlined />
              個案量測人數
            </div>
            <div class="card-content">
              <div class="value">{{ stat.persion.case }}</div>
            </div>
          </a-card>
          <a-card :bodyStyle="cardBodyStype">
            <div class="card-title">
              <UserOutlined />
              個案量測人次
            </div>
            <div class="card-content">
              <div class="value">{{ stat.persionTime.case }}</div>
            </div>
          </a-card>
          <a-card :bodyStyle="cardBodyStype">
            <div class="card-title">
              <UserOutlined />
              量測總人次
            </div>
            <div class="card-content">
              <div class="value">{{ stat.persionTime.total }}</div>
            </div>
          </a-card>
        </a-col>
        <a-col :xs="24" :sm="12" :md="12" :lg="12" :xl="8">
          <MeasuerPercentage :data="stat.measurements" />
        </a-col>
        <a-col :xs="24" :sm="12" :md="12" :lg="12" :xl="8">
          <CaseMeasuerPercentage :data="stat.measurements" />
        </a-col>
      </a-row>
      <a-row :gutter="48" style="margin-top: 30px">
        <a-col
          :span="24"
          v-if="Object.keys(dateRecord).length > 0"
          class="chart"
        >
          <PersonCount :data="dateRecord" />
        </a-col>
      </a-row>
      <a-row :gutter="48" style="margin-top: 30px">
        <a-col
          :span="24"
          v-if="Object.keys(dateRecord).length > 0"
          class="chart"
        >
          <MeasureTotal :data="dateRecord" />
        </a-col>
      </a-row>
      <DataTable :searchResult="searchResult" />
    </a-spin>
    <a-modal
      v-model:visible="storeModalVisible"
      :footer="null"
      title="選擇門市或分區"
      @cancel="getStoreList"
    >
      <StoreTags />
      <div style="margin-top: 12px">
        <StoreTreeSelector />
      </div>
    </a-modal>
  </div>
</template>

<script>
  import { reactive, ref, computed, watch } from 'vue'
  import { useStore } from 'vuex'
  import { QuestionCircleOutlined, UserOutlined } from '@ant-design/icons-vue'
  import { notification } from 'ant-design-vue'
  import XLSX from 'xlsx'
  import export2Excel from '@/utils/export2Excel'
  import { getStoresMemberRecordTime } from '@/api/healthy'
  import { dateAdd, parseTime, mealString, round } from '@/utils/index'
  import StoreTreeSelector from '@/components/StoreTreeSelector'
  import StoreTags from '@/components/StoreTags'
  import PersonCount from './charts/PersonCount'
  import MeasureTotal from './charts/MeasureTotal'
  import MeasuerPercentage from './charts/MeasuerPercentage'
  import CaseMeasuerPercentage from './charts/CaseMeasuerPercentage'
  import DataTable from './DataTable'
  import {
    getMeasurementDisplayFields,
    preprocessBodyWeightFat,
  } from '@/utils/measurement'

  export default {
    components: {
      QuestionCircleOutlined,
      UserOutlined,
      StoreTreeSelector,
      StoreTags,
      PersonCount,
      MeasureTotal,
      MeasuerPercentage,
      CaseMeasuerPercentage,
      DataTable,
    },
    setup() {
      const store = useStore()
      const storeList = store.getters['store/storeList']
      const storeChecked = computed(
        () => store.getters['store/checkedListTree']
      )
      const isLoading = ref(false)
      const searchForm = reactive({
        store_id: [],
        date: [dateAdd(0), dateAdd(0)],
      })
      let uniqueDate = [] // 此查詢裡有幾個不同天
      let uniqueDateStore = [] // 此查詢裡有幾個不同天
      const userDateRecord = ref([]) // 每人每日記錄
      const dateRecord = ref({}) // 每日統計
      const dateStoreRecord = ref({}) // 每日各門市統計
      const searchResult = ref([])
      const storeModalVisible = ref(false)

      const getStoreList = () => {
        searchForm.store_id = storeChecked.value
          .filter((item) => item.split('-').length == 4)
          .map((item) => parseInt(item.split('-').pop()))
        // 清除統計資料
        userDateRecord.value = []
        dateRecord.value = {}
        searchResult.value = []
        dateStoreRecord.value = {}
        uniqueDate = []
        uniqueDateStore = []
      }
      watch(storeChecked, () => {
        getStoreList()
      })
      const stat = reactive({
        // 人數
        persion: {
          case: 0,
          total: 0,
        },
        // 人次
        persionTime: {
          case: 0,
          total: 0,
        },
        // 量測項目
        measurements: {
          bpCase: 0,
          osCase: 0,
          glucoseCase: 0,
          bodyWeightCase: 0,
          bodyTempCase: 0,
          bpTotal: 0,
          osTotal: 0,
          glucoseTotal: 0,
          bodyWeightTotal: 0,
          bodyTempTotal: 0,
        },
      })

      const getRecord = async () => {
        isLoading.value = true
        uniqueDate = []
        uniqueDateStore = []
        dateRecord.value = {}
        dateStoreRecord.value = {}
        if (searchForm.store_id == '') return
        const { status, data } = await getStoresMemberRecordTime({
          ...searchForm,
          responseFormat: 'records',
        })
        if (status != 200) {
          notification.error({
            message: '查無資料',
          })
          isLoading.value = false
          stat.persion.case = 0
          stat.persion.total = 0
          stat.persionTime.case = 0
          stat.persionTime.castotale = 0
          stat.measurements.case = 0
          stat.measurements.castotale = 0
          return
        }
        searchResult.value = data.data

        // 量測人數(頁面顯示)
        const caseData = data.data.filter((item) => item.subject != null)
        stat.persion.case = [
          ...new Set(caseData.map((item) => item.subject.memberID)),
        ].length
        // stat.persion.total = stat.persion.case
        // if (data.data.find((item) => item.subject == null)) {
        //   stat.persion.total += data.data.find(
        //     (item) => item.subject == null
        //   ).data.length
        // }

        // 量測人次(頁面顯示)
        stat.persionTime.case = data.data.filter(
          (item) => item.subject != null
        ).length
        stat.persionTime.total = data.data.length

        // 量測紀錄項目數(頁面顯示)
        stat.measurements = {
          bpCase: 0,
          osCase: 0,
          glucoseCase: 0,
          bodyWeightCase: 0,
          bodyTempCase: 0,
          bpTotal: 0,
          osTotal: 0,
          glucoseTotal: 0,
          bodyWeightTotal: 0,
          bodyTempTotal: 0,
        }
        data.data
          .filter((item) => item.subject != null)
          .map((item) => {
            if (item.bpMeasurements.length > 0) stat.measurements.bpCase++
            if (item.oxygenSatMeasurements.length > 0)
              stat.measurements.osCase++
            if (item.glucoseMeasurements.length > 0)
              stat.measurements.glucoseCase++
            if (item.bodyWeightFatMeasurements.length > 0)
              stat.measurements.bodyWeightCase++
            if (item.bodyTempMeasurements.length > 0)
              stat.measurements.bodyTempCase++
          })
        data.data.map((item) => {
          if (item.bpMeasurements.length > 0) stat.measurements.bpTotal++
          if (item.oxygenSatMeasurements.length > 0) stat.measurements.osTotal++
          if (item.glucoseMeasurements.length > 0)
            stat.measurements.glucoseTotal++
          if (item.bodyWeightFatMeasurements.length > 0)
            stat.measurements.bodyWeightTotal++
          if (item.bodyTempMeasurements.length > 0)
            stat.measurements.bodyTempTotal++
        })

        // 抓取有幾個不同日期、門市(匯出資料預處理)
        data.data.map((record) => {
          // 不同天
          if (!uniqueDate.includes(record.measured.substring(0, 10)))
            uniqueDate.push(record.measured.substring(0, 10))

          // 不同[天,門市]
          if (
            !uniqueDateStore.includes(
              `${record.measured.substring(0, 10)},${record.location.code}`
            )
          )
            uniqueDateStore.push(
              `${record.measured.substring(0, 10)},${record.location.code}`
            )
        })

        uniqueDate.sort()
        uniqueDateStore.sort()

        // 統計不同天(繪圖用)
        uniqueDate.map((date) => {
          dateRecord.value[date] = {
            // 人數
            persion: {
              case: 0,
              total: 0,
            },
            // 人次
            persionTime: {
              case: 0,
              total: 0,
            },
            // 量測項目
            measurements: {
              bpCase: 0,
              osCase: 0,
              glucoseCase: 0,
              bodyWeightCase: 0,
              bodyTempCase: 0,
              bpTotal: 0,
              osTotal: 0,
              glucoseTotal: 0,
              bodyWeightTotal: 0,
              bodyTempTotal: 0,
            },
          }

          dateRecord.value[date].persion.case = data.data
            .filter((item) => item.subject != null)
            .filter((item) => item.measured.substring(0, 10) == date).length

          dateRecord.value[date].persionTime.case = data.data
            .filter((item) => item.subject != null)
            .filter((item) => item.measured.substring(0, 10) == date).length

          dateRecord.value[date].persionTime.total = data.data.filter(
            (item) => item.measured.substring(0, 10) == date
          ).length

          // 個案量測數
          dateRecord.value[date].measurements.bpCase = data.data
            .filter((item) => item.subject != null)
            .filter((item) => item.measured.substring(0, 10) == date)
            .filter((item) => item.bpMeasurements.length > 0).length
          dateRecord.value[date].measurements.osCase = data.data
            .filter((item) => item.subject != null)
            .filter((item) => item.measured.substring(0, 10) == date)
            .filter((item) => item.oxygenSatMeasurements.length > 0).length
          dateRecord.value[date].measurements.glucoseCase = data.data
            .filter((item) => item.subject != null)
            .filter((item) => item.measured.substring(0, 10) == date)
            .filter((item) => item.glucoseMeasurements.length > 0).length
          dateRecord.value[date].measurements.bodyWeightCase = data.data
            .filter((item) => item.subject != null)
            .filter((item) => item.measured.substring(0, 10) == date)
            .filter((item) => item.bodyWeightFatMeasurements.length > 0).length
          dateRecord.value[date].measurements.bodyTempCase = data.data
            .filter((item) => item.subject != null)
            .filter((item) => item.measured.substring(0, 10) == date)
            .filter((item) => item.bodyTempMeasurements.length > 0).length

          // 總量測數
          dateRecord.value[date].measurements.bpTotal = data.data
            .filter((item) => item.measured.substring(0, 10) == date)
            .filter((item) => item.bpMeasurements.length > 0).length
          dateRecord.value[date].measurements.osTotal = data.data
            .filter((item) => item.measured.substring(0, 10) == date)
            .filter((item) => item.oxygenSatMeasurements.length > 0).length
          dateRecord.value[date].measurements.glucoseTotal = data.data
            .filter((item) => item.measured.substring(0, 10) == date)
            .filter((item) => item.glucoseMeasurements.length > 0).length
          dateRecord.value[date].measurements.bodyWeightTotal = data.data
            .filter((item) => item.measured.substring(0, 10) == date)
            .filter((item) => item.bodyWeightFatMeasurements.length > 0).length
          dateRecord.value[date].measurements.bodyTempTotal = data.data
            .filter((item) => item.measured.substring(0, 10) == date)
            .filter((item) => item.bodyTempMeasurements.length > 0).length
        })

        // 統計不同[天,門市](匯出用)
        uniqueDateStore.map((dateStore) => {
          dateStoreRecord.value[dateStore] = {
            // 人數
            persion: {
              case: 0,
              total: 0,
            },
            // 人次
            persionTime: {
              case: 0,
              total: 0,
            },
            // 量測項目
            measurements: {
              bpCase: 0,
              osCase: 0,
              glucoseCase: 0,
              bodyWeightCase: 0,
              bodyTempCase: 0,
              bpTotal: 0,
              osTotal: 0,
              glucoseTotal: 0,
              bodyWeightTotal: 0,
              bodyTempTotal: 0,
            },
          }

          dateStoreRecord.value[dateStore].persion.case = data.data
            .filter((item) => item.subject != null)
            .filter(
              (item) =>
                item.measured.substring(0, 10) == dateStore.split(',')[0]
            )
            .filter(
              (item) => item.location.code == dateStore.split(',')[1]
            ).length

          dateStoreRecord.value[dateStore].persionTime.case = data.data
            .filter((item) => item.subject != null)
            .filter(
              (item) =>
                item.measured.substring(0, 10) == dateStore.split(',')[0]
            )
            .filter(
              (item) => item.location.code == dateStore.split(',')[1]
            ).length

          dateStoreRecord.value[dateStore].persionTime.total = data.data
            .filter(
              (item) =>
                item.measured.substring(0, 10) == dateStore.split(',')[0]
            )
            .filter(
              (item) => item.location.code == dateStore.split(',')[1]
            ).length

          // 個案量測數
          dateStoreRecord.value[dateStore].measurements.bpCase = data.data
            .filter((item) => item.subject != null)
            .filter(
              (item) =>
                item.measured.substring(0, 10) == dateStore.split(',')[0]
            )
            .filter((item) => item.location.code == dateStore.split(',')[1])
            .filter((item) => item.bpMeasurements.length > 0).length
          dateStoreRecord.value[dateStore].measurements.osCase = data.data
            .filter((item) => item.subject != null)
            .filter(
              (item) =>
                item.measured.substring(0, 10) == dateStore.split(',')[0]
            )
            .filter((item) => item.location.code == dateStore.split(',')[1])
            .filter((item) => item.oxygenSatMeasurements.length > 0).length
          dateStoreRecord.value[dateStore].measurements.glucoseCase = data.data
            .filter((item) => item.subject != null)
            .filter(
              (item) =>
                item.measured.substring(0, 10) == dateStore.split(',')[0]
            )
            .filter((item) => item.location.code == dateStore.split(',')[1])
            .filter((item) => item.glucoseMeasurements.length > 0).length
          dateStoreRecord.value[dateStore].measurements.bodyWeightCase =
            data.data
              .filter((item) => item.subject != null)
              .filter(
                (item) =>
                  item.measured.substring(0, 10) == dateStore.split(',')[0]
              )
              .filter((item) => item.location.code == dateStore.split(',')[1])
              .filter(
                (item) => item.bodyWeightFatMeasurements.length > 0
              ).length
          dateStoreRecord.value[dateStore].measurements.bodyTempCase = data.data
            .filter((item) => item.subject != null)
            .filter(
              (item) =>
                item.measured.substring(0, 10) == dateStore.split(',')[0]
            )
            .filter((item) => item.location.code == dateStore.split(',')[1])
            .filter((item) => item.bodyTempMeasurements.length > 0).length

          // 總量測數
          dateStoreRecord.value[dateStore].measurements.bpTotal = data.data
            .filter(
              (item) =>
                item.measured.substring(0, 10) == dateStore.split(',')[0]
            )
            .filter((item) => item.location.code == dateStore.split(',')[1])
            .filter((item) => item.bpMeasurements.length > 0).length
          dateStoreRecord.value[dateStore].measurements.osTotal = data.data
            .filter(
              (item) =>
                item.measured.substring(0, 10) == dateStore.split(',')[0]
            )
            .filter((item) => item.location.code == dateStore.split(',')[1])
            .filter((item) => item.oxygenSatMeasurements.length > 0).length
          dateStoreRecord.value[dateStore].measurements.glucoseTotal = data.data
            .filter(
              (item) =>
                item.measured.substring(0, 10) == dateStore.split(',')[0]
            )
            .filter((item) => item.location.code == dateStore.split(',')[1])
            .filter((item) => item.glucoseMeasurements.length > 0).length
          dateStoreRecord.value[dateStore].measurements.bodyWeightTotal =
            data.data
              .filter(
                (item) =>
                  item.measured.substring(0, 10) == dateStore.split(',')[0]
              )
              .filter((item) => item.location.code == dateStore.split(',')[1])
              .filter(
                (item) => item.bodyWeightFatMeasurements.length > 0
              ).length
          dateStoreRecord.value[dateStore].measurements.bodyTempTotal =
            data.data
              .filter(
                (item) =>
                  item.measured.substring(0, 10) == dateStore.split(',')[0]
              )
              .filter((item) => item.location.code == dateStore.split(',')[1])
              .filter((item) => item.bodyTempMeasurements.length > 0).length
        })
        isLoading.value = false
      }

      // 身體組成欄位資訊
      const bodyWeightFatFields = getMeasurementDisplayFields('bodyWeightFat')
      const exportExcel = async () => {
        isLoading.value = true
        const aoa = [
          [
            '量測日期',
            '量測時間',
            '會員卡號',
            '會員姓名',
            '量測小站編號',
            '量測門市',
            // TODO '門市服務人員',
            '收縮壓',
            '舒張壓',
            '脈壓差',
            '脈搏',
            '心律不整(ARR)',
            '早期收縮(PC)',
            '心房顫動(Afib)',
            '血壓量測時間',
            '血壓量測設備',
            '血糖',
            '用餐狀態',
            '血糖量測時間',
            '血糖量測設備',
            '血氧最高',
            '血氧最低',
            '脈搏最高',
            '脈搏最低',
            '血氧量測時間',
            '血氧量測設備',
            '體溫',
            '體溫量測時間',
            '體溫量測設備',
            // 體重相關數值
            ...bodyWeightFatFields.map((e) => e.displayName),
            '身體組成量測時間',
            '身體組成量測設備',
          ],
        ]

        for (const record of searchResult.value) {
          if (record.subject == null) continue
          const preproccessBodyWeightFatData = record
            .bodyWeightFatMeasurements[0]
            ? preprocessBodyWeightFat(record.bodyWeightFatMeasurements[0])
            : undefined
          if (record.subject.cache == null) {
            const custInfo = await store.dispatch('cust/getCustInfo', {
              memberID: record.subject.memberID,
            })
            const name = custInfo.name
            aoa.push([
              parseTime(record.measured, '{y}-{m}-{d}'),
              parseTime(record.measured, '{h}:{i}:{s}'),
              record.subject.cardNumber,
              name,
              record.hubCode,
              record.location.title,
              // TODO 門市服務人員 ,
              record.bpMeasurements[0]?.systolic,
              record.bpMeasurements[0]?.diastolic,
              record.bpMeasurements[0]?.pulsePressureDifference,
              record.bpMeasurements[0]?.pulse,
              record.bpMeasurements[0]?.arr,
              record.bpMeasurements[0]?.pc,
              record.bpMeasurements[0]?.afib,
              parseTime(record.bpMeasurements[0]?.measured),
              record.bpMeasurements[0]?.deviceID,
              record.glucoseMeasurements[0]?.bloodGlucose,
              mealString(record.glucoseMeasurements[0]?.meal),
              parseTime(record.glucoseMeasurements[0]?.measured),
              record.glucoseMeasurements[0]?.deviceID,
              record.oxygenSatMeasurements[0]?.spo2Highest,
              record.oxygenSatMeasurements[0]?.spo2Lowest,
              record.oxygenSatMeasurements[0]?.pulseHighest,
              record.oxygenSatMeasurements[0]?.pulseLowest,
              parseTime(record.oxygenSatMeasurements[0]?.measured),
              record.oxygenSatMeasurements[0]?.deviceID,
              record.bodyTempMeasurements[0]?.bodyTemperature
                ? round(
                    Number(record.bodyTempMeasurements[0]?.bodyTemperature),
                    1
                  )
                : '',
              parseTime(record.bodyTempMeasurements[0]?.measured),
              record.bodyTempMeasurements[0]?.deviceID,
              // 體重相關數值
              ...bodyWeightFatFields.map((e) => {
                // 沒資料 or 不支援的欄位
                if (
                  preproccessBodyWeightFatData === undefined ||
                  !e.supportDevices.includes(
                    preproccessBodyWeightFatData.deviceID
                  )
                )
                  return ''
                return preproccessBodyWeightFatData[e.field]
              }),
              parseTime(record.bodyWeightFatMeasurements[0]?.measured),
              parseTime(record.bodyWeightFatMeasurements[0]?.measured),
              record.bodyWeightFatMeasurements[0]?.deviceID,
            ])
          } else {
            const name = record.subject.cache.name
            aoa.push([
              parseTime(record.measured, '{y}-{m}-{d}'),
              parseTime(record.measured, '{h}:{i}:{s}'),
              record.subject.cardNumber,
              name,
              record.hubCode,
              record.location.title,
              // TODO 門市服務人員 ,
              record.bpMeasurements[0]?.systolic,
              record.bpMeasurements[0]?.diastolic,
              record.bpMeasurements[0]?.pulsePressureDifference,
              record.bpMeasurements[0]?.pulse,
              record.bpMeasurements[0]?.arr,
              record.bpMeasurements[0]?.pc,
              record.bpMeasurements[0]?.afib,
              parseTime(record.bpMeasurements[0]?.measured),
              record.bpMeasurements[0]?.deviceID,
              record.glucoseMeasurements[0]?.bloodGlucose,
              mealString(record.glucoseMeasurements[0]?.meal),
              parseTime(record.glucoseMeasurements[0]?.measured),
              record.glucoseMeasurements[0]?.deviceID,
              record.oxygenSatMeasurements[0]?.spo2Highest,
              record.oxygenSatMeasurements[0]?.spo2Lowest,
              record.oxygenSatMeasurements[0]?.pulseHighest,
              record.oxygenSatMeasurements[0]?.pulseLowest,
              parseTime(record.oxygenSatMeasurements[0]?.measured),
              record.oxygenSatMeasurements[0]?.deviceID,
              record.bodyTempMeasurements[0]?.bodyTemperature
                ? round(
                    Number(record.bodyTempMeasurements[0]?.bodyTemperature),
                    1
                  )
                : '',
              parseTime(record.bodyTempMeasurements[0]?.measured),
              record.bodyTempMeasurements[0]?.deviceID,
              // 體重相關數值
              ...bodyWeightFatFields.map((e) => {
                // 沒資料 or 不支援的欄位
                if (
                  preproccessBodyWeightFatData === undefined ||
                  !e.supportDevices.includes(
                    preproccessBodyWeightFatData.deviceID
                  )
                )
                  return ''
                return preproccessBodyWeightFatData[e.field]
              }),
              parseTime(record.bodyWeightFatMeasurements[0]?.measured),
              record.bodyWeightFatMeasurements[0]?.deviceID,
            ])
          }
        }

        const fileDate = parseTime(new Date().getTime(), '{y}{m}{d}{h}{i}{s}')
        let worksheet1 = XLSX.utils.aoa_to_sheet(aoa)

        export2Excel({
          worksheets: {
            worksheet1,
          }, // 匯出excel的資料，key表示工作表名，value表示對應工作表的 sheet 資料，支援匯出多個工作表
          fileName: `健康紀錄清冊_${fileDate}`, // 匯出檔名
          type: 'xlsx', // 檔案匯出型別
        })
        isLoading.value = false
      }

      const exportExcel1 = () => {
        let aoa = [
          [
            '量測日期',
            '大分區',
            '小分區',
            '量測門市',
            '個案量測人數',
            '個案量測人次',
            '個案血壓量測人次',
            '個案血氧量測人次',
            '個案血糖量測人次',
            '個案體溫量測人次',
            '個案身體組成量測人次',
            '總量測人次',
            '總血壓量測人次',
            '總血氧量測人次',
            '總血糖量測人次',
            '總體溫量測人次',
            '總身體組成量測人次',
            '個案健康項目數',
            '總健康項目數',
          ],
        ]

        for (const dateStore in dateStoreRecord.value) {
          const storeInfo = storeList.find(
            (item) => item.store_id == dateStore.split(',')[1]
          )
          aoa.push([
            dateStore.split(',')[0],
            storeInfo.area_name,
            storeInfo.area_sub_name,
            storeInfo.store_name,
            dateStoreRecord.value[dateStore].persion.case,
            dateStoreRecord.value[dateStore].persionTime.case,
            dateStoreRecord.value[dateStore].measurements.bpCase,
            dateStoreRecord.value[dateStore].measurements.osCase,
            dateStoreRecord.value[dateStore].measurements.glucoseCase,
            dateStoreRecord.value[dateStore].measurements.bodyWeightCase,
            dateStoreRecord.value[dateStore].measurements.bodyTempCase,
            dateStoreRecord.value[dateStore].persionTime.total,
            dateStoreRecord.value[dateStore].measurements.bpTotal,
            dateStoreRecord.value[dateStore].measurements.osTotal,
            dateStoreRecord.value[dateStore].measurements.glucoseTotal,
            dateStoreRecord.value[dateStore].measurements.bodyTempTotal,
            dateStoreRecord.value[dateStore].measurements.bodyWeightTotal,
            dateStoreRecord.value[dateStore].measurements.bpCase +
              dateStoreRecord.value[dateStore].measurements.osCase +
              dateStoreRecord.value[dateStore].measurements.glucoseCase +
              dateStoreRecord.value[dateStore].measurements.bodyWeightCase +
              dateStoreRecord.value[dateStore].measurements.bodyTempCase,
            dateStoreRecord.value[dateStore].measurements.bpTotal +
              dateStoreRecord.value[dateStore].measurements.osTotal +
              dateStoreRecord.value[dateStore].measurements.glucoseTotal +
              dateStoreRecord.value[dateStore].measurements.bodyWeightTotal +
              dateStoreRecord.value[dateStore].measurements.bodyTempTotal,
          ])
        }

        let worksheet1 = XLSX.utils.aoa_to_sheet(aoa)
        export2Excel({
          worksheets: {
            sheet1: worksheet1,
          }, // 匯出excel的資料，key表示工作表名，value表示對應工作表的 sheet 資料，支援匯出多個工作表
          fileName: `門市量測統計表`, // 匯出檔名
          type: 'xlsx', // 檔案匯出型別
        })
      }
      return {
        dateFormat: 'YYYY/MM/DD',
        isLoading,
        dateAdd,
        storeModalVisible,
        userDateRecord,
        dateRecord,
        searchForm,
        getStoreList,
        getRecord,
        stat,
        exportExcel,
        exportExcel1,
        cardBodyStype: { padding: '12px 0' },
        searchResult,
      }
    },
  }
</script>
<style lang="less" scoped>
  .ant-card {
    position: relative;
    margin: 10px 0;
    border-radius: 10px;
    text-align: center;
    // min-height: 200px;
    border: 1px solid #000000;
    align-items: center;
    @title-font-size: 26px;

    .card-title {
      font-weight: 500;
      font-size: @title-font-size;
      line-height: @title-font-size;
      letter-spacing: 0.2px;
      vertical-align: middle;
    }
    .card-content {
      justify-content: center;
      .value {
        color: #2f80ed;
        font-size: 52px;
        font-weight: 300;
        margin-bottom: -12px;
      }
    }
  }
  .ant-row {
    margin: 5px 0;
  }
  .chart {
    height: 400px;
  }
</style>
