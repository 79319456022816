<template>
  <v-chart autoresize :option="option" />
</template>

<script>
  import { ref, onMounted, watch, toRef } from 'vue'
  export default {
    props: {
      data: {
        type: Object,
        default: () => {},
      },
    },
    setup(props) {
      const dataCopy = toRef(props, 'data')
      const option = ref(null)
      const echartInit = () => {
        const data = [
          { value: dataCopy.value.bpCase, name: '血壓' },
          { value: dataCopy.value.osCase, name: '血氧' },
          { value: dataCopy.value.glucoseCase, name: '血糖' },
          { value: dataCopy.value.bodyWeightCase, name: '身體組成' },
          { value: dataCopy.value.bodyTempCase, name: '體溫' },
        ]
        option.value = {
          backgroundColor: 'white',
          title: {
            text: `個案量測記錄項目數`,
            textStyle: {
              fontSize: '20',
            },
            left: 'center',
          },
          color: ['#EB5757', '#FDCF07', '#F08719', '#2F80ED', '#219653'],
          grid: {
            left: '30',
            right: '30',
          },
          tooltip: {
            trigger: 'item',
          },
          legend: {
            orient: 'vertical',
            left: 'left',
          },
          series: [
            {
              type: 'pie',
              radius: '80%',
              label: {
                formatter: '{b}\n{d}%',
                // position: 'inside',
              },
              data,
              emphasis: {
                itemStyle: {
                  shadowBlur: 10,
                  shadowOffsetX: 0,
                  shadowColor: 'rgba(0, 0, 0, 0.5)',
                },
              },
            },
          ],
        }
      }
      onMounted(() => {
        echartInit()
      })

      watch(
        () => props.data,
        () => {
          echartInit()
        }
      )

      return {
        option,
      }
    },
  }
</script>

<style></style>
